<template>
    <div>
        <template v-if="row.item.offering_list">
            <a v-for="(number, id) in row.item.offering_list"
               @click.prevent="$root.$children[0].openModal('kva-modal', id)"
               href="#"
            >
                {{ number }}
            </a>
        </template>
        <template v-else>
            <a @click.prevent="$root.$children[0].openModal('kva-modal', row.item.offering_id)"
               href="#">{{ row.item.project }}</a>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>